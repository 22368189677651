import { useState } from "react";
import "./index.scss";


export default function TableEditRow(props: any) {
    let [item, setItem] = useState(props.data);
    let [parentItem, setParentItem] = useState(props.item);

    const elonChange = (e: any) => {
        let el = { ...item };
        if (!el[e.target.name].updated_text) {
            el[e.target.name].initial_value = item[e.target.name].text;
        }

        el[e.target.name].updated_text = e.target.value;
        // mark changed items
        el[e.target.name].changed = el[e.target.name].initial_value !== el[e.target.name].updated_text;
        setItem(el);
    }
    const onSave = (e: any) => {
        let hasChanges: boolean = false;
        for (let el_key in item) {
            if ('updated_text' in item[el_key]) {
                item[el_key].text = item[el_key].updated_text;
                if (!hasChanges && item[el_key].changed) {
                    hasChanges = true;
                }
            }
        }
        props.toggleEditable(parentItem, true);
    }

    const cancelEdit = (item: any) => {
        props.toggleEditable(parentItem, false);
    }

    let items = [<td>
        <button
            className="btn btn-sm btn-success"
            onClick={() => onSave(item)}>
            &nbsp;Save
        </button>&nbsp;
        <a href="#" className="text-sm text-white" onClick={(e) => cancelEdit(item)}>Cancel</a>
    </td>];

    for (let el_key in item) {
        if(!props.isValidColumn(el_key)){
            continue;
        }
        items.push(<td>
            <input
                name={el_key}
                defaultValue={item[el_key].text}
                onChange={(e) => elonChange(e)} /></td>);
    }

    return (
        <>{items}</>
    );
}
