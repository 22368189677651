import { createTheme } from "@fluentui/react";

const darkGreyPalette = {
    themePrimary: "#E5E6E6",
    themeLighterAlt: "#e8e8e8",
    themeLighter: "#ebebeb",
    themeLight: "#eeeeee",
    themeTertiary: "#f1f1f1",
    themeSecondary: "#f4f4f4",
    themeDarkAlt: "#f6f6f6",
    themeDark: "#f9f9f9",
    themeDarker: "#fcfcfc",
    neutralLighterAlt: "#26282d",
    neutralLighter: "#2d3036",
    neutralLight: "#3a3d43",
    neutralQuaternaryAlt: "#42454c",
    neutralQuaternary: "#484c53",
    neutralTertiaryAlt: "#646870",
    neutralTertiary: "#eeeeee",
    neutralSecondary: "#f1f1f1",
    neutralPrimaryAlt: "#f4f4f4",
    neutralPrimary: "#E5E6E6",
    neutralDark: "#f9f9f9",
    black: "#fcfcfc",
    white: "#1E2024",
};

const greenButtonPalette = {
    themePrimary: "#78ad0e",
    themeLighterAlt: "#050701",
    themeLighter: "#131c02",
    themeLight: "#243404",
    themeTertiary: "#486808",
    themeSecondary: "#6a990c",
    themeDarkAlt: "#83b61f",
    themeDark: "#94c13a",
    themeDarker: "#add165",
    neutralLighterAlt: "#393e43",
    neutralLighter: "#40454b",
    neutralLight: "#4c5157",
    neutralQuaternaryAlt: "#53585f",
    neutralQuaternary: "#595f65",
    neutralTertiaryAlt: "#73787f",
    neutralTertiary: "#dfdfdf",
    neutralSecondary: "#e4e4e4",
    neutralPrimaryAlt: "#e9e9e9",
    neutralPrimary: "#cfcfcf",
    neutralDark: "#f4f4f4",
    black: "#f9f9f9",
    white: "#32363B",
};

const greyButtonPalette = {
    themePrimary: "#949799",
    themeLighterAlt: "#060606",
    themeLighter: "#181818",
    themeLight: "#2d2d2e",
    themeTertiary: "#595b5c",
    themeSecondary: "#838587",
    themeDarkAlt: "#9fa1a3",
    themeDark: "#adb0b1",
    themeDarker: "#c3c4c6",
    neutralLighterAlt: "#262a2f",
    neutralLighter: "#383e44",
    neutralLight: "#24282c",
    neutralQuaternaryAlt: "#222529",
    neutralQuaternary: "#202328",
    neutralTertiaryAlt: "#1f2226",
    neutralTertiary: "#f0f2f5",
    neutralSecondary: "#f2f4f6",
    neutralPrimaryAlt: "#f5f6f8",
    neutralPrimary: "#e9ecef",
    neutralDark: "#fafbfb",
    black: "#fcfdfd",
    white: "#272B30",
};

const darkGreyTheme = createTheme({ palette: darkGreyPalette });
const greenTheme = createTheme({ palette: greenButtonPalette });
const greyTheme = createTheme({ palette: greyButtonPalette });

export function getDarkGreyTheme() {
    return darkGreyTheme;
}

export function getPrimaryGreenTheme() {
    return greenTheme;
}

export function getPrimaryGreyTheme() {
    return greyTheme;
}
