/**
 * Constants used throughout application
 */
export const constants = {
    pdfjsWorkerSrc(version: string) {
        return `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.js`;
    },

    pdfjsCMapUrl(version: string) {
        return `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/cmaps/`;
    },
    convertedImageFormat: "image/jpeg",
    convertedImageQuality: 0.7,
};
