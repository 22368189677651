import logoSmall from "./logoSmall.png";

export default function Header() {
  return (<header className="bg-dark-light">
    <div>
      <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <a href="/" className="d-flex align-items-center mb-lg-0 text-dark text-decoration-none">
          <img src={logoSmall} alt="logo" width="64px" />
          <div className="text-white" style={{ marginLeft: 20, fontWeight: 30 }}>Rinkt Validation Station</div>
        </a>

        {/*<ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
          <li><a href="#" className="nav-link px-2 link-secondary">Overview</a></li>
         </ul>*/}
      </div>
    </div>
  </header>)
}
