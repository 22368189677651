import './App.scss';
import './index.scss'
import TableFieldItem from './TableFieldItem';
import TableInfo from './TableInfo';
import Header from "./Header";
import { useEffect, useRef, useState } from "react";
import { CefContent, getBlobData } from "./CefContent";
import { ErrorDialog } from "./Components/ErrorDialog";
import { isPdfFileExtension } from "./Utils/utils";
import PredictPage from "./pages/predict/PredictPage";
import { ITag } from './model/applicationModels';

import FieldItem from './FieldItem';

import microsoft_response_data from "./analyze_result.json";
import tagsData from "./tags.json"

declare var CefSharp: any;
declare var boundAsync: any;

function App() {

  const [fields, setFields] = useState<any>();
  const [analyzeResult, setAnalyzeResult] = useState<any>();
  const [documentFileName, setDocumentFileName] = useState<any>('default');

  const [documentLoaded, setDocumentLoaded] = useState<boolean>();
  const [isPDfFile, setIsPDfFile] = useState<boolean>();
  const [file, setFile] = useState<File>();
  const [fileUrl, setFileUrl] = useState<string>();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [tagsSet, setTagsSet] = useState<ITag[]>();
  const [tableTagsSet, setTableTagsSet] = useState<Set<string>>();
  const [contentByPage, setContentByPage] = useState({});

  const [hasUserChanges, setHasUserChanges] = useState(false);
  const [changedPages, setChangedPages] = useState<Map<string, any[]>>(new Map<string, any[]>());

  const initializeFieldsData = (fieldsData: any) => {
    for (let field in fieldsData) {
      const data = (fieldsData as any)[field];
      if ('valueArray' in data) {
        data['valueArray'].map((el: any, ix: number) => { return el.id = ix })
      }
    }

    setFields(fieldsData);
  }

  const initializeAnalyzeResult = (data: any) => {
    setAnalyzeResult(data);
  }

  useEffect(() => {
    async function getData() {
      if (typeof CefSharp !== 'undefined' && CefSharp && CefSharp.BindObjectAsync) {
        await CefSharp.BindObjectAsync('boundAsync');

        const cefContentString = await boundAsync.getDocumentContent() as string;
        const cefContent = JSON.parse(cefContentString) as CefContent;
        let allData = JSON.parse(cefContent.JsonResponseContent);
        const contentByPage = cefContent.JsonResponseContentByPage && JSON.parse(cefContent.JsonResponseContentByPage);
        allData = mergeResults(allData, contentByPage);
        if(contentByPage){// if we have multiple responses, one per page, save it
          setContentByPage(contentByPage);
        }
        const fieldsData = allData['analyzeResult']['documentResults'][0]['fields']
        initializeAnalyzeResult(allData);
        initializeFieldsData(fieldsData);
        if (cefContent.tags) {
          // setTagsSet(new Set(cefContent.tags.filter(t => !t.includes('->'))));
          // setTableTagsSet(new Set(cefContent.tags.filter(t => t.includes('->')).map(t => t.split('->')[1])));
          setTagsSet(cefContent.tags)
        }
        setDocumentFileName(cefContent.DocumentFileName);
        setIsPDfFile(isPdfFileExtension(cefContent.DocumentFileName));
        setDocumentLoaded(true);
        if (cefContent.DocumentFileContent) {
          const blob = await getBlobData(cefContent.DocumentFileContent);
          const fileType = getFileType(cefContent.DocumentFileName);
          const file = new File([blob], cefContent.DocumentFileName, { type: fileType });
          setFile(file);
          setFileUrl(URL.createObjectURL(file));
        }
      }
    }
    getData();
  }, []);

  function mergeResults(initialAnalyzeResult: any, analyseResultByPage: any){
    if(!analyseResultByPage && !initialAnalyzeResult.merged) return initialAnalyzeResult;

    const fieldsData = initialAnalyzeResult['analyzeResult']['documentResults'][0]['fields']
    for(let pageNumber in analyseResultByPage){
      const intPageNumber = parseInt(pageNumber);

      const pageResults = analyseResultByPage[pageNumber]['analyzeResult']['pageResults'];
      const readResults = analyseResultByPage[pageNumber]['analyzeResult']['readResults'];
      if(pageResults && pageResults.length > 0 && initialAnalyzeResult['analyzeResult']['pageResults']){
        pageResults[0].page = intPageNumber;
        initialAnalyzeResult['analyzeResult']['pageResults'].push(pageResults[0]);
      }
      if(readResults && readResults.length > 0 && initialAnalyzeResult['analyzeResult']['readResults']){
        readResults[0].page = intPageNumber;
        initialAnalyzeResult['analyzeResult']['readResults'].push(readResults[0]);
      }

      let pageFields = analyseResultByPage[pageNumber]['analyzeResult']['documentResults'][0]['fields'];
      for (let field in fieldsData) {
        const data = fieldsData[field];
        const pageData = pageFields[field];
        if ('valueArray' in data) {
          if('valueArray' in pageData){
            for (let i = 0; i < pageData['valueArray'].length; i++) {
              const valueObject = pageData['valueArray'][i]['valueObject'];
              pageData['valueArray'][i].page = pageNumber;//added to mark the page
              for (let objkey in valueObject){
                valueObject[objkey].page = intPageNumber;
              }
            }
            data['valueArray'] = data['valueArray'].concat(pageData['valueArray']);
          }
        } else if(pageData.text && pageData.text !== '') {
          fieldsData[field] = pageData;
          pageData.page = intPageNumber;
        }
      }
    }
    return initialAnalyzeResult;
  }

  function getFileType(fileName: string) {
    const splitName = fileName.split(".");
    let fileExtension = splitName[splitName.length - 1];
    if (fileExtension){
      fileExtension = fileExtension.toLowerCase();
    }
    switch (fileExtension) {
      case 'pdf':
        return 'application/pdf';
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'tiff':
        return 'image/tiff';
      default:
        return 'application/pdf';
    }
  }

  async function setDefaultData() {
    const fieldsData = microsoft_response_data['analyzeResult']['documentResults'][0]['fields']
    const fileUrl = 'f1.pdf';
    initializeFieldsData(fieldsData);
    initializeAnalyzeResult(microsoft_response_data);
    setTagsSet(tagsData);
    setIsPDfFile(false);
    setDocumentLoaded(true);

    await fetch(fileUrl)
      .then(r => r.blob())
      .then(response => {
        const fileType = getFileType(fileUrl);
        const file = new File([response], fileUrl, { type: fileType });
        setFile(file);
        setFileUrl(URL.createObjectURL(file));
      })
  };

  if (typeof CefSharp === 'undefined' && fields === undefined) {
    setDefaultData()
  }

  if (fields === undefined) {
    return null;
  }

  const validate = (field_to_validate: string) => {
    const updated_data = { ...(fields as any) }
    updated_data[field_to_validate].validated = true
    setFields(updated_data)
    analyzeResult['analyzeResult']['documentResults'][0]['fields'] = updated_data
  }

  const fieldChanged = (hasUserChanges, field) => {
    setHasUserChanges(prevState => !prevState ? hasUserChanges : prevState);

    if(field){ // set fields for changed pages
      setChangedPages(prevState => {
        if (!prevState) {
          prevState = new Map<string, any[]>();
        }
        const pageNumber = field.page ? field.page.toString() : "1";
        if (!prevState.has(pageNumber)){
          prevState.set(pageNumber, []);
        }
        if (hasUserChanges && !prevState.get(pageNumber).includes(field)){
          prevState.get(pageNumber).push(field);
        }
        else if(!hasUserChanges){
          const arrayIndex = prevState.get(pageNumber).indexOf(field);
          if (arrayIndex > -1){
            prevState.get(pageNumber).splice(arrayIndex, 1);
          }
        }
        return prevState;
      });
    }
  }

  const sendMultiPageResult =  () => {
    let hasChanges;
    let mainResult: string = null;
    let resultContentByPage = null;
    changedPages.forEach((pageFields, pageNumber) => { // set changed fields to multi pages
      if(pageFields && pageFields.length > 0){
        hasChanges = true;
        if(!mainResult){
          analyzeResult.merged = true;
          mainResult = JSON.stringify(analyzeResult);
          analyzeResult.merged = undefined;
        }
      }
      if(contentByPage.hasOwnProperty(pageNumber)){
        const pageContentAnalyzeResult = contentByPage[pageNumber];
        if(!resultContentByPage){
          resultContentByPage = {};
        }
        resultContentByPage[pageNumber] = pageContentAnalyzeResult;
        const localFields = pageContentAnalyzeResult['analyzeResult']['documentResults'][0]['fields'];
        for (let field in localFields) {
          const data = localFields[field];
          if ('valueArray' in data) {
            for (let i = 0; i < data['valueArray'].length; i++) {
              const valueObject = data['valueArray'][i];
              const index = pageFields.findIndex(i => i.id === valueObject.id);
              if(index > -1){
                data['valueArray'][i] = valueObject;
              }
            }
          } else {
            const index = pageFields.indexOf(data);
            if(index > -1){
              localFields[field] = data;
            }
          }
        }
      }
    });
    //---------
    if(Object.entries(contentByPage).length > 0){ // we have multi pages results
      if(changedPages.has("1") && changedPages.get("1").length > 0){
        for (let field in fields) {
          const data = fields[field];
          if ('valueArray' in data) {
            for (let i = 0; i < data['valueArray'].length; i++) {
              const valueObject = data['valueArray'][i]['valueObject'];
              for (let objkey in valueObject){
                if(valueObject[objkey].page && valueObject[objkey].page !== 1){
                  delete valueObject[objkey];
                }
              }
              if(Object.entries(valueObject).length === 0){
                data['valueArray'].splice(i, 1);
                i--;
              }
            }
          } else if(data.page && data.page !== 1) {
            delete fields[field];
          }
        }
        contentByPage["1"] = analyzeResult;
      }
    }
    if(hasChanges){
      boundAsync.submitToVerification(mainResult, resultContentByPage && JSON.stringify(resultContentByPage));
    } else {
      boundAsync.submitToVerification(null, null);
    }
  }

  const submitData = () => {
    if (typeof boundAsync !== 'undefined') {
      analyzeResult['analyzeResult']['documentResults'][0]['fields'] = fields;
      if(Object.entries(contentByPage).length > 0){
        try {
          sendMultiPageResult();
        } catch (e) {
          boundAsync.submitToVerification(null, null);
        }
      } else {
        boundAsync.submitToVerification(changedPages.has("1") && changedPages.get("1").length > 0 ? JSON.stringify(analyzeResult) : null, null);
      }
    } else {
      alert("data is validated");
    }
  }

  return (
    <>
      <div className="app-shell">
        <Header />
        <div className="app-main bg-dark">
          <div className="app-content bg-dark-light text-white">
            {file && <PredictPage
              tags={tagsSet}
              analyzeResult={analyzeResult}
              fields={fields}
              file={file}
              fileUrl={fileUrl}
              updateFields={setFields}
              setHasUserChanges={fieldChanged}
              markAsValidated={validate}
              submitValidations={submitData}
            />}
          </div>
          <ErrorDialog open={showError} onClose={() => { setShowError(false) }} message={errorMessage} />
        </div >
      </div>
    </>
  );
}

export default App;
