import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import { DialogActions } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
});

export function ErrorDialog(props: { open: boolean, message: string, onClose: (() => void) }) {
    const { open, message, onClose } = props;


    const handleClose = () => {
        onClose();
    };

    const keyPress = (e: any) => {
        if (e.key == 'Enter') {
            e.preventDefault();
            onClose();
        }
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} onKeyPress={keyPress}>
            <DialogTitle id="simple-dialog-title">
                <div style={{ display: 'flex' }}>
                    <ErrorIcon style={{ color: 'red', marginTop: '4px' }} />
                    <div style={{ marginLeft: '5px' }}>Error</div>
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
}
